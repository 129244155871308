import React from 'react'

const Footer = () => {
  return (
    <>
    <footer>
      <div><a href='https://pocopota.com' target='_blank'>Development: PocoPota</a></div>
      <div>©HueShot</div>
    </footer>
    </>
  )
}

export default Footer
